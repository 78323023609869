/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  useDispatch, connect, useSelector
} from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import AddForexDepositModal from "./AddForexDepositModal";
import {
  approveFxDeposit,
  fetchForexDeposits,
  rejectFxDeposit,
} from "store/forexTransactions/deposits/actions";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "../../../../assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { useLocation } from "react-router-dom";
import CustomDropdown from "components/Common/CustomDropDown";
import Filter from "./DepositFilter";
import Badge from "components/Common/Badge";
import formatDate from "helpers/formatDate";
import FeatherIcon from "feather-icons-react";
import TransactionModal from "components/Common/TransactionModal";
import AddFiniticPayFirstPhaseModal from "./AddFiniticPayFirstPhaseModal";
import AddFiniticPaySecondPhaseModal from "./AddFiniticPaySecondPhaseModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MerchantDepositAddressModal from "./MerchantDepositAddressModal";
import MinifiText from "components/Common/MinifiText";

function DepositForex(props) {
  // get query paramerters from url
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [approveModal, setApproveModal] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState({});

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [ownDepositAddress, setOwnDepositAddress] = useState(true);
  const {
    userData,
    settings
  } = useSelector((state) => ({
    userData: state.Profile.userData,
  }));

  const depositApproveModal = (deposit) => {
    setSelectedDeposit(deposit);
    setApproveModal(true);
  };

  const approveDeposit = (deposit) => {
    dispatch(
      approveFxDeposit({
        id: deposit._id,
        customerId: deposit.customerId._id,
        amount: deposit.amount,
        note: deposit.note
      })
    );
    setApproveModal(false);
  };

  const depositReject = (deposit) => {
    dispatch(
      rejectFxDeposit({
        id: deposit._id,
        customerId: deposit.customerId._id,
      })
    );
    // setShowNotifaction(true);
  };
  const columns = [
    {
      dataField: "checkbox",
      text: (
        <input
          type="checkbox"
          id="check-all-deposits"
          onChange={() =>
            checkAllBoxes("check-all-deposits", ".deposit-checkbox")
          }
        />
      ),
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "_id",
      text: props.t("Transaction Id"),
      formatter: (val) => (
        val?.checkoutUrl ? <>
          <Link
            target="_blank"
            to={{
              pathname: val.checkoutUrl,
            }}>{val._id}</Link>
        </> : val._id
      )
    },
    {
      dataField: "customerId",
      text: props.t("Receiver Wallet"),
      formatter: (val) => {
        return (
          <div>
          <span className="no-italics" style={{ fontWeight: "bold" }}>
            <MinifiText text={val?.receiver?.address} />
            </span>
        </div>
        );
      },
    },
    {
      dataField: "processedBy",
      text: props.t("Blockchain Id"),
      formatter: (val) => {
        return (
          <div>
            <span className="no-italics" style={{ fontWeight: "bold" }}>
              <MinifiText text={val?.blockChainTransactionId} />
            </span>
          </div>
        );
      },
    },
    {
      dataField: "currency",
      text: props.t("Currency"),
    },
    // {
    //   dataField: "paid",
    //   text: props.t("paid"),
    // },
    // {
    //   dataField: "fee",
    //   text: props.t("Fee"),
    // },
    {
      dataField: "amount",
      text: props.t("Amount"),
      formatter: (val) => parseFloat(val.amount),
    },
    {
      dataField: "paid",
      text: props.t("Paid"),
      formatter: (val) => parseFloat(val.paidAmount),
    },
    {
      dataField: "fees",
      text: props.t("Fee"),
      formatter: (val) => {
        return (
          <div>
          <span className="no-italics" style={{ fontWeight: "bold" }}>
            {val?.fees?.fees?.$numberDecimal}
          </span>
        </div>
        );
      },
    },
    {
      dataField: "totalPaid",
      text: props.t("Total Paid"),
      formatter: (val) => {
        const amount = parseFloat(val?.paidAmount);
        let fees = parseFloat(val?.fees?.fees?.$numberDecimal);
        if (isNaN(fees)) {
          fees = 0;
        }
        const result = amount - fees;  
      
        return isNaN(result) || result === undefined ? "-" : result;
      },
    },
    // {
    //   dataField: "salesRep",
    //   text: props.t("Sales Rep"),
    // },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => <Badge status={val.status}></Badge>,
    },
    // {
    //   dataField: "dropdown",
    //   isDummyField: true,
    //   editable: false,
    //   text: props.t("Actions"),
    // },
  ];

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const initFilteredValues = {
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: query.get("status") || "",
    currency: "",
    paid: "",
    amount: "",
    fee: "",
    gateway: "",
    tradingAccount: "",
    agent: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };
  const loadForexDeposits = (page, limit) => {
    if (searchInput && searchInput.length > 3 && userData._id) {
      dispatch(
        fetchForexDeposits({
          limit,
          page,
          filteredValues: {
            ...filteredValues,
            customerId: userData._id,
          },
          searchText: searchInput,
        })
      );
    } else {
      dispatch(
        fetchForexDeposits({
          limit,
          page,
          filteredValues: {
            ...filteredValues,
            customerId: userData._id,
          },
        })
      );
    }
  };


  const closeNotifaction = () => {
    setShowNotifaction(false);
  };

  useEffect(() => {
    loadForexDeposits(1, sizePerPage);
  }, [
    props.addLoading,
    sizePerPage,
    1,
    searchInput,
    selectedFilter,
    filteredValues,
    props.depositResponseMessage,
  ]);

  useEffect(() => {
  }, [
    props.addForexDepositScondPhaesOpen,
    isSubmitted,
  ]);

  return (
    <React.Fragment>
      <Notification
        onClose={closeNotifaction}
        body={props.t("The deposit has been updated successfully")}
        show={showNotication}
        header={props.t("Deposit Update")}
        logo={logo}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">
                  {props.t(`Deposits(${props.totalDocs})`)}
                  {filteredValues && filteredValues.length}
                  {filteredValues && filteredValues.length > 0 ? filteredValues.map(a => { return a.value }) : ""}
                  <FeatherIcon
                    icon="refresh-cw"
                    className="icon-lg ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      loadForexDeposits(1, sizePerPage);
                    }}
                  />
                </CardTitle>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                <div className="d-flex flex-row align-items-center">
                  <SearchBar
                    handleSearchInput={handleSearchInput}
                    placeholder={props.t("Search for deposits")}
                  />
                  <Filter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  />
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                  {
                    ownDepositAddress ? <MerchantDepositAddressModal hasDepositAddress={userData.hasDepositAddress} setOwnDepositAddress={setOwnDepositAddress}
                    /> : null
                  }
                  {
                    !isSubmitted &&  <AddFiniticPayFirstPhaseModal
                      setIsSubmitted={setIsSubmitted}
                      isSubmitted={isSubmitted}
                      customerId={userData?._id}
                      setOwnDepositAddress={setOwnDepositAddress}
                    />
                  }
                  {
                    (isSubmitted && props.addForexDepositScondPhaesOpen) &&
                    <>
                      <AddFiniticPaySecondPhaseModal
                        url={props?.addForexDepositScondPhaesUrl}
                        setIsSubmitted={setIsSubmitted}
                      />
                    </>
                  }
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table  table-hover ">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                        )}
                      </Tr>
                    </Thead>

                    {props.totalDocs === 0 ? (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {props.fetchLoading && <TableLoader colSpan={4} />}
                        {!props.fetchLoading && (
                          <>
                            <Tr>
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                                st
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                      </Tbody>
                    ) : (
                      <Tbody
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {props.fetchLoading && <TableLoader colSpan={4} />}
                        {!props.fetchLoading &&
                          props.forexDeposits.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td
                                  key={`${rowIndex}-${index}`}
                                  className="pt-4"
                                >
                                  {column.dataField === "checkbox" ? (
                                    <input
                                      className="deposit-checkbox"
                                      type="checkbox"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                  {column.dataField === "dropdown" && (
                                    <CustomDropdown
                                      permission={
                                        props.depositsPermissions.actions
                                      }
                                      status={row.status}
                                      approve={() => depositApproveModal(row)}
                                      reject={() => depositReject(row)}
                                    />
                                  )}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                      </Tbody>
                    )}
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadForexDeposits}
                    docs={props.forexDeposits}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* {
        <ReceiptModal
          content={selectedContent}
          open={detailsModal}
          onClose={() => setDetailsModal(false)}
        />
      } */}
      {approveModal && <TransactionModal
        show={approveModal}
        title="Approve Deposit"
        showAmount={true}
        toggle={() => {
          setApproveModal(!approveModal);
        }}
        t={props.t}
        onSubmit={approveDeposit}
        data={selectedDeposit}
      >
      </TransactionModal>}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  fetchLoading: state.forexDepositReducer.fetchLoading || false,
  addLoading: state.forexDepositReducer.addLoading || false,
  forexDeposits: state.forexDepositReducer.forexDeposits || [],
  addForexDepositScondPhaesOpen: state.forexDepositReducer.addForexDepositScondPhaesOpen || false,
  addForexDepositScondPhaesUrl: state.forexDepositReducer.addForexDepositScondPhaesUrl || "",
  page: state.forexDepositReducer.page || 1,
  totalDocs: state.forexDepositReducer.forexTotalDocs || 0,
  totalPages: state.forexDepositReducer.totalPages || 0,
  hasNextPage: state.forexDepositReducer.hasNextPage,
  hasPrevPage: state.forexDepositReducer.hasPrevPage,
  limit: state.forexDepositReducer.limit,
  nextPage: state.forexDepositReducer.nextPage,
  pagingCounter: state.forexDepositReducer.pagingCounter,
  prevPage: state.forexDepositReducer.prevPage,
  depositsPermissions: state.Profile.depositsPermissions || {},
  depositResponseMessage: state.forexDepositReducer.depositResponseMessage,
  tradingAccounts: state.tradingAccountReducer.tradingAccounts,
});
export default connect(mapStateToProps, null)(withTranslation()(DepositForex));
